@import "reset.scss";
@import "variables/index";
@import "./pages/home/index";
@import "./pages/index";

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: #FFFFFF !important;
  overflow-x: hidden !important;
}

.thumbnailClassSukaNAHUIBLAT {
  height: 10px;
}