.pricing .pricing__header-wrapper .Polaris-Button.Polaris-Button--primary {
  padding: 19px 74px;
}

.pricing .pricing__header-wrapper .Polaris-Button__Text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.pricing .pricing__table-wrapper .Polaris-Button.Polaris-Button--primary {
  height: 60px;
  border-radius: 0
}

.pricing.lang-uk .pricing__header-wrapper .Polaris-Button__Text {
  font-size: 1rem;
  line-height: 1.5rem;
}
