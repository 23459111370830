.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #707071;
  z-index: 24;
  transform: translateY(120%);
  transition-timing-function: ease-in-out;
  transition-property: opacity, visibility, transform;
  transition-duration: 300ms;
  visibility: hidden;
  opacity: 0;

  &--active {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
  }
}

.container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  padding: 11px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p, a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    border: none;
    background-color: transparent;
  }
}

.link-wrapper {
  button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    border: none;
    background-color: transparent;
    text-decoration: underline;
  }
}

.links {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.text-container {
  max-width: 60%;
}

.text-container--nocookie {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.close-wrapper {
  cursor: pointer;
  margin-left: 40px;
  path {
    fill: #fafbfb;
  }
}


.actions-wrapper {
  display: flex;
  align-items: center;
}

.enable-text {
  text-decoration: underline;
  cursor: pointer;
  word-wrap: break-word;
  white-space: nowrap
}

@media screen and (max-width: 767px){
  .container {
    flex-direction: column;
  }

  .text-container {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 420px){
  .actions-wrapper {
    flex-direction: column;
  }
}