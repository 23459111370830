.alert {
  border: 2px solid #d30a0a;
  background-color: #e7c9c9;
  color: #d30a0a;
  font-weight: 500;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  min-width: 180px;
  border-radius: 6px;
  position: fixed;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity, top, bottom;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  z-index: 25;
  width: fit-content;

  &--active {
    top: 20%;
    visibility: visible;
    opacity: 1;
  }
}

.icon-wrapper {
  margin-right: 10px;
}

.warning {
  background-color: #fff5ea;
  color: black;
  border: 1px solid #ffc306;


  svg {
    fill: #b98900;

    path {
      fill: #b98900;
    }
  }
}

.bottom {
  top: unset;
  bottom: -10%;
}

.bottom.alert--active {
  top: unset;
  bottom: 12%;
}

.action {
  text-decoration: underline;
  padding: 7px 16px;
  cursor: pointer;
  transition: 300ms background-color ease-in-out;
}

.icon-wrapper {
  display: flex;
  align-items: center;
}

.close {
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.message {
  margin-right: 10px;
}

.message, .actions {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .warning {
    width: 80%;
    flex-direction: column;
  }
}