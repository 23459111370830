@use "styles/variables/colors/colors";

.reviews__slider-wrapper {
  position: relative;

  .swiper-wrapper {
    padding: 5px;
  }
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #9B9B9B;
  box-sizing: content-box;
}

.swiper-pagination-bullet-active {
  border: 4px solid colors.$primaryLinksBg;
  background-color: #fff;
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaders__slider, .reviews__slider-wrapper {
  .swiper-pagination {
    display: none;
  }

  .swiper-pagination {
    bottom: -55px;
  }

}

.swiper {
  position: unset;
}

@media screen and (max-width: 820px) {
  .reviews__slider-wrapper {
    .swiper-container {
      max-width: 380px;
    }
  }

}


@media screen and (max-width: 820px) {
  .reviews__slider-wrapper {
    position: relative;

    .swiper-pagination {
      display: flex;
    }
  }
}

@media screen and (max-width: 540px) {
  .reviews__slider-wrapper {
    .swiper-pagination {
      display: flex;
    }

    .swiper-container {
      max-width: 300px;
    }
  }
}