@use "styles/variables/colors/colors";

.main {
  max-width: 512px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #FFFFFF;
  z-index: 25;
  padding: 11px 18px 28px;
  overflow: auto;
  height: 80%;
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity, top;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;

  &--active {
    top: 50%;
    opacity: 1;
    visibility: visible;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 24;
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  &--active {
    opacity: 1;
    visibility: visible;
  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.logo-wrapper {
  width: 150px;

  svg {
    width: 100%;
    height: auto;
  }
}

.close-wrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.header {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #6D7175;
  margin-bottom: 30px;
}

.content {
  padding-top: 20px;
  p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8C9196;
    margin-bottom: 15px;
  }

  a {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #008060;
    text-decoration: none;
    display: block;
    width: fit-content;
  }

  .allow-btn-wrapper {
    margin-bottom: 30px;
  }
}

.toggles-wrapper {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #999a9b;
}

.item {
  border: 1px solid #C9CCCF;

  .always-active {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #008060;
    margin: 0;
  }
}

.activator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 22px;
}

.toggle-text-wrapper {
  p {
    margin-bottom: 0;
    text-align: initial;
  }
}

.activator {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 8px 0;

  .plus-wrapper {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    svg {
      transform: rotate(45deg);
      transition: transform 300ms ease-in-out;
    }
  }

  &--active {
    .plus-wrapper {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}



.cookie-button {
  min-width: 140px;
  height: 40px;
  background: #C9CCCF;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #C9CCCF;
  display: flex;
  overflow: hidden;
  padding: 0;
  transition: 300ms justify-content ease-in-out;

  div {
    display: block;
    width: 69px;
    height: 38px;
    border-right: 1px solid #C9CCCF;
    background-color: #F2F7FE;
    box-shadow: 01px 0 rgba(0, 0, 0, 0.05), inset 0px -1px 0px #BCBFC3, inset 1px 0px 0px #BBBFC3, inset 0px 1px 0px #BBBFC3;
    transition-property: transform, background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
  }

  &--active {
    div {
      transform: translateX(100%);
      border-right: unset;
      border-left: 1px solid #C9CCCF;
      background-color: colors.$iconSuccess;
    }
  }
}

.cookie-desc {
  padding-top: 4px;
  padding-left: 25px;
  padding-right: 25px;
}
