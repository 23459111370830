@import "hero";
.take-moment .Polaris-Button {
  padding: 19px 74px;
  .Polaris-Button__Text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
  }
}

@media screen and (max-width: 640px) {
  .take-moment .Polaris-Button {
    padding: 13px 40px;
    .Polaris-Button__Text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 460px) {
  .take-moment .Polaris-Button {
    padding: 11px 25px;
    .Polaris-Button__Text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}