.recharge-hero-accent {
  * {
    transition: transform 100ms ease-in-out;
    transform: translateX(-10%) translateY(-10%);
  }
}

@media screen and (max-width: 1023px){
  .recharge-hero {
    .double-links {
      width: 30%;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 639px) {
  .recharge-hero {
    .double-links {
      width: 30%;
      flex-direction: row;
      min-width: 100%;
    }
  }
}