.viewmore .Polaris-Button__Text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
}

.viewmore .Polaris-Button.Polaris-Button--primary {
  padding: 28px 40px;
}

@media screen and (max-width: 985px) {
  .viewmore .Polaris-Button.Polaris-Button--primary {
    padding: 15px 22px;
  }
  .viewmore .Polaris-Button__Text {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}