@import "react-flow/viewmore";
@import "react-flow/pricing";
@import "react-flow/features";
@import "react-flow/hero";
@import "selectors/hero";
@import "recharge/index";
@import "stuff";

.text-link {
  text-decoration: none;
  color: #025d46;
}


#__next {
  .Polaris-Popover__Pane.Polaris-Scrollable.Polaris-Scrollable--vertical.Polaris-Scrollable--hasBottomShadow {
    overflow: hidden;
  }

}

.nav-page-link {
  position: relative;
  display: inline-block;
  padding-left: 12px;
}

.current-page {
  &:before {
    position: absolute;
    content: "";
    background-color: #007F5F;
    border-bottom: 3px solid #007F5F;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
    height: 100%;
    width: 2px;
    top: 0;
    left: -8px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

@media screen and (max-width: 767px) {
  .double-links .Polaris-Button {
    background-color: transparent;
    border: none;
    box-shadow: none;

    .Polaris-Icon__Svg {
      fill: black;
    }
  }

  .double-links .Polaris-Button--fullWidth {
    width: unset;
  }

  .double-links .Polaris-Button--primary {
    background-color: black;

    .Polaris-Icon__Svg {
      fill: white;
    }
  }
}
