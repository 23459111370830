.about__content .Polaris-Icon svg {
  fill: #fff;
}

.app-store-btn .Polaris-Button.Polaris-Button--outline.Polaris-Button--monochrome {
  border-color: #dad8d8;
  box-shadow: none;
}

.app-store-btn .Polaris-Button__Content {
  color: #fff;
}